<template>
  <div class="container">
    <el-image
      class="banner"
      :src="require('@as/serve/strict/banner.png')"
    ></el-image>
    <div class="main">
      <div class="section">
        <div class="head">
          <div class="title">商品秒杀</div>
          <div class="control">
            <el-image
              class="control_icon"
              :src="require('@as/serve/strict/icon_3.png')"
            ></el-image>
            <el-image
              class="control_icon"
              :src="require('@as/serve/strict/icon_4.png')"
            ></el-image>
          </div>
        </div>
        <div class="card_container line">
          <div
            class="card"
            v-for="item in 5"
            v-bind:key="item"
            @click="toDetail()"
          >
            <el-image
              class="img"
              :src="require('@as/serve/strict/card_img.png')"
            ></el-image>
            <div class="title">距离结束还有</div>
            <div class="subtitle">120X 变焦/120W秒充/120Hz屏幕</div>
            <div class="price">
              <div class="num">5299元</div>
              <div class="line_price">5499元</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="head normal">
          <div class="title">专属推荐</div>
          <div class="subtitle">为你寻觅世间好物</div>
        </div>
        <div class="card_container">
          <div class="card" v-for="item in 10" v-bind:key="item">
            <el-image
              class="img"
              :src="require('@as/serve/strict/banner.png')"
            ></el-image>
            <div class="title">距离结束还有</div>
            <div class="subtitle">120X 变焦/120W秒充/120Hz屏幕</div>
            <div class="price">
              <div class="num">5299元</div>
              <div class="line_price">5499元</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="main">
        <div class="section no_margin">
          <div class="head normal">
            <div class="title">大家都在说</div>
            <div class="subtitle">生活，没有统一标准</div>
          </div>
          <div class="card_container hight">
            <div class="color_card" v-for="item in 4" v-bind:key="item">
              <el-image
                class="img"
                :src="require('@as/serve/strict/banner.png')"
              ></el-image>
              <div class="info">
                <div class="title">
                  <div>奔****流行</div>
                  <div class="time">2020-10-21</div>
                </div>
                <div class="name">小米小爱触屏音箱</div>
                <div class="detail">
                  这个价格还是很划算的。经济实惠，
                  物美价廉，质量非常好，跟卖家描述 的完全一致。
                </div>
              </div>
            </div>
            <el-image
              class="card_control"
              :src="require('@as/serve/strict/icon_1.png')"
            ></el-image>
            <el-image
              class="card_control"
              :src="require('@as/serve/strict/icon_2.png')"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toDetail() {
      this.$router.push('strictDetail')
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  background: #f4f6fa;
}
.banner {
  width: 100%;
  height: 400px;
}
.footer {
  padding: 40px 0 0;
  width: 100%;
  background: #fff;
}
.main {
  margin: 0 auto;
  padding-bottom: 50px;
  width: 1200px;
  .section {
    margin-top: 45px;
    &.no_margin {
      margin: 0;
    }
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.normal {
        justify-content: start;
      }
      .title {
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 300;
        color: #333333;
      }
      .subtitle {
        margin-left: 21px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
      .control {
        display: flex;
        border: 1px solid #e2e3e5;
      }
      .control_icon {
        padding: 6px 13px;
        cursor: pointer;
        &:last-child {
          border-left: 1px solid #e2e3e5;
        }
        & /deep/ .el-image__inner {
          width: 8px;
          height: 12px;
        }
      }
    }
    .card_container {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      &.hight {
        position: relative;
        align-items: flex-start;
        height: 390px;
      }
      &.line {
        flex-wrap: nowrap;
        .card {
          margin-bottom: 0;
          border-top: 1px solid #1c9dbf;
          cursor: pointer;
          &:nth-child(2) {
            border-color: #48b69e;
          }
          &:nth-child(3) {
            border-color: #3f94dc;
          }
          &:nth-child(4) {
            border-color: #c94a49;
          }
          &:nth-child(5) {
            border-color: #eeae4f;
          }
        }
      }
      .card {
        margin-bottom: 10px;
        width: 230px;
        height: 330px;
        background: #ffffff;
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        }
        .img {
          margin-top: 40px;
          width: 160px;
          height: 160px;
        }
        .title {
          margin-top: 26px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 500;
          color: #333333;
        }
        .subtitle {
          margin-top: 9px;
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #999999;
        }
        .price {
          margin-top: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          .num {
            margin-right: 6px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #1c9dbf;
          }
          .line_price {
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 500;
            text-decoration: line-through;
            color: #999999;
          }
        }
      }
      .color_card {
        position: relative;
        width: 285px;
        height: 285px;
        background: #bdcbe1;
        &:nth-child(2) {
          background: #eaf8fa;
        }
        &:nth-child(3) {
          background: #e5e6eb;
        }
        &:nth-child(4) {
          background: #97d8f0;
        }
        .img {
          margin-top: 20px;
          width: 285px;
          height: 189px;
        }
        .info {
          position: absolute;
          top: 210px;
          left: 50%;
          padding: 25px 20px;
          width: 275px;
          height: 180px;
          transform: translateX(-50%);
          background: #ffffff;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
          box-sizing: border-box;
          .title {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
            .time {
              margin-left: 20px;
            }
          }
          .name {
            margin-top: 16px;
            font-size: 16px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #333333;
          }
          .detail {
            margin-top: 19px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
      .card_control {
        position: absolute;
        top: 153px;
        width: 44px;
        height: 44px;
        cursor: pointer;
        &:first-child {
          left: 0;
        }
        &:last-child {
          right: 0;
        }
      }
    }
  }
}
</style>